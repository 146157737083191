<template>
    <div class="sbanner" :class="{ 'disable-safe-area': !safeArea }">
        <div class="title">
            <p>
                Edit: <span v-if="creation">{{ creation.name || '' }}</span>
            </p>
            <a-icon
                type="close"
                :style="{ fontSize: '20px', cursor: 'pointer', padding: '8px' }"
                @click="handleClose()"
            />
        </div>
        <div class="sbanner__main scroll" v-if="hasTemplate">
            <div class="sbanner__side">
                <section class="edition__box scroll">
                    <template-form
                        v-if="showUI"
                        @controlUpdated="handleControlUpdate($event)"
                        :template="creation.template"
                        :creation="creation"
                    />
                    <banner-product-form
                        v-if="showUI && hasProduct"
                        :product-id="creation.productId"
                        :creation="creation"
                        @controlUpdated="
                            handleControlUpdate(
                                $event.action,
                                $event.mergedProductData
                            )
                        "
                    />
                </section>
            </div>
            <div class="sbanner__preview" ref="editBox">
                <div class="ruler-horizontal" ref="ruleVBox">
                    <vue-ruler
                        type="horizontal"
                        :width="rulerHorizontalLen"
                        :marks="[creation.width]"
                        :scrollPos="0"
                        :zoom="scale"
                    />
                </div>
                <div class="ruler-vertical" ref="ruleHBox">
                    <vue-ruler
                        type="vertical"
                        :height="rulerVerticalLen"
                        :marks="[creation.height]"
                        :scrollPos="0"
                        :zoom="scale"
                    />
                </div>
                <div class="sbanner__size">
                    <div class="sbanner__undo" v-if="hasUndo">
                        <a-button @click="undoLastAction()">
                            <a-icon type="undo" />
                        </a-button>
                    </div>
                    <div class="sbanner__smart-object">
                        <a-checkbox v-model="smartChecked">
                            Smart Adapt
                        </a-checkbox>
                    </div>
                    <banner-movable-controls />
                    <a-button class="safe-area" @click="safeArea = !safeArea">
                        Safe area
                    </a-button>
                    <scale-stepper v-model="scale" @input="calculateRuler()" />
                </div>
                <div class="sbanner__editor" :style="style">
                    <div class="banner scroll" ref="renderCreation">
                        <template-holder
                            v-if="showUI"
                            :creationModel="creation"
                            :listenToChanges="true"
                            @dataChanged="handleTemplateHolderUpdate"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="sbanner__bottom">
            <a-button
                class="sbanner__reset"
                @click="handleResetCreationSettings"
            >
                <a-icon type="reload" />
                Reset settings
            </a-button>
            <a-button type="primary" @click="saveAndClose"
                >Save changes and close
            </a-button>
        </div>
    </div>
</template>

<script>
import BannerMovableControls from '@/components/banner/BannerMovableControls'
import BannerProductForm from '@/components/banner/BannerProductForm'
import TemplateHolder from '@/components/project/components/TemplateHolder'
import TemplateForm from '@/components/project/components/form/TemplateForm'
import ScaleStepper from '@/components/shared/form/ScaleStepper.vue'
import { connectionService } from '@/services/project-connection.service'
import { Modal } from 'ant-design-vue'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import VueRuler from '@scena/vue-ruler'

export default {
    name: 'BannerPage',
    components: {
        ScaleStepper,
        BannerMovableControls,
        TemplateHolder,
        TemplateForm,
        BannerProductForm,
        VueRuler,
    },
    data() {
        return {
            // this is a quickfix for refreshing the state @see: reloadUIState()
            showUI: true,
            scale: 1,
            safeArea: false,
            eventStackUndoQueue: [],
            rulerHorizontalLen: 0,
            rulerVerticalLen: 0,
            smartChecked: false,
        }
    },
    mounted() {
        setTimeout(() => this.calculateRuler(), 800)
    },
    computed: {
        ...mapGetters('connectionTemplate', { creation: 'selectedCreation' }),
        style() {
            return { transform: `scale(${this.scale})`, pointerEvents: 'none' }
        },
        projectId() {
            return Number(this.$route.params.projectId)
        },
        hasTemplate() {
            return Boolean(this.creation?.template)
        },
        hasProduct() {
            return Boolean(this.creation?.productId)
        },
        hasUndo() {
            return this.eventStackUndoQueue.length > 0
        },
    },
    methods: {
        ...mapActions(['showAlertSuccess', 'showAlertError']),
        ...mapActions('connectionTemplate', [
            'setNewCreationsSettings',
            'setNewConnectionSettings',
            'cleanCurrentCreationSettings',
            'selectedCreation',
            'updateHolderByCreationId',
            'updateHolder',
        ]),
        calculateRuler() {
            // const OFFSET = 35
            requestAnimationFrame(() => {
                const { editBox, renderCreation, ruleVBox, ruleHBox } =
                    this.$refs
                if (!(editBox && renderCreation)) {
                    return
                }
                const { width, height, left, top } =
                    editBox.getBoundingClientRect()
                const { left: rcLeft, top: rcTop } =
                    renderCreation.getBoundingClientRect()
                const baseHScroll = rcLeft - left
                const baseVScroll = rcTop - top
                ruleVBox.style.left = baseHScroll + 'px'
                ruleHBox.style.top = baseVScroll + 'px'
                this.rulerHorizontalLen = width - baseHScroll
                this.rulerVerticalLen = height - baseVScroll - 3 // dummy -3 to fix the v-scroll (disappear)
            })
        },
        saveAndClose() {
            this.handleClose()
        },
        handleClose() {
            const { projectId, connectionId } = this.$route.params
            this.$router.push({
                name: 'project-connection',
                params: { projectId, connectionId },
            })
        },
        savePreviousValue(action, productData) {
            const { type, id, key, eventId } = action
            const { baseSettings, connectionSettings } = this.creation
            const isSmartUpdate = this.smartChecked
            const settingsSource = isSmartUpdate
                ? connectionSettings
                : baseSettings
            // get previous value:
            const value = settingsSource?.[type]?.[id]?.[key] || null
            const lastAction = this.eventStackUndoQueue.at(-1)?.action
            // Do not save if action is the same
            if (
                lastAction &&
                lastAction.value === value &&
                lastAction.type === type &&
                lastAction.id === id &&
                lastAction.key === key
            ) {
                return
            }
            this.eventStackUndoQueue.push({
                action: { type, id, key, value, eventId },
                productData,
                isSmartUpdate,
            })
        },
        async undoLastAction() {
            const currentEvent = this.eventStackUndoQueue.pop()
            // find all with the same eventId:
            const allWithSameIds = this.eventStackUndoQueue.filter(
                (e) => e?.action?.eventId === currentEvent?.action?.eventId
            )
            allWithSameIds.push(currentEvent)
            await Promise.all(
                allWithSameIds.map(({ action, productData, isSmartUpdate }) => {
                    this.handleTemplateHolderUpdate(
                        action,
                        productData,
                        false,
                        isSmartUpdate
                    )
                })
            )
            // remove sameId events:
            this.eventStackUndoQueue = this.eventStackUndoQueue.filter(
                (e) => e?.action.eventId !== currentEvent?.action?.eventId
            )
        },
        async handleControlUpdate(action, productData, trackActionUndo = true) {
            try {
                const { id: creationId } = this.creation || {}
                if (productData) {
                    // update templateHolder only if not a reset product value.
                    const isNotAValueReset = Object.values(
                        action.value || {}
                    ).every((v) => v !== null)
                    if (creationId && isNotAValueReset) {
                        this.updateHolderByCreationId({
                            ...action,
                            value: productData,
                            creationId,
                        })
                    }
                } else if (!this.smartChecked) {
                    // @fix: this update cannot be performed when Smart Adapt (glitch effect).
                    this.updateHolderByCreationId({ ...action, creationId })
                }
                if (trackActionUndo) {
                    this.savePreviousValue(action, productData)
                }
                const updatedSettingsByCreationsId =
                    await connectionService.updateCreationSettings(
                        {
                            projectId: this.projectId,
                            creationId: this.creation.id,
                        },
                        action
                    )
                this.setNewCreationsSettings(updatedSettingsByCreationsId)
                this.creation.updateSettings(
                    updatedSettingsByCreationsId[creationId]
                )
            } catch (e) {
                if (!axios.isCancel(e)) {
                    this.showAlertError(`Update settings failure: ${e.message}`)
                }
            }
        },
        // Handles all TControl.EVENT_TYPES.DATA_CHANGED form all controls.
        async handleTemplateHolderUpdate( // updates made by user mouse movement & banner editor & goBack button
            action,
            productData = null,
            trackActionUndo = true,
            isSmartUpdate = this.smartChecked
        ) {
            if (isSmartUpdate) {
                // This order MATTERS !
                await this.handleSmartUpdateOfWholeConnection(action)
                return this.handleSmartOverrideBehavior(
                    action,
                    isSmartUpdate,
                    trackActionUndo
                )
            }
            this.handleSmartOverrideBehavior(
                action,
                isSmartUpdate,
                trackActionUndo
            )
            if (action.key.startsWith('smart_')) {
                // drop smart update for Creation!
                return
            }
            this.updateHolderByCreationId({
                ...action,
                creationId: this.creation.id,
            })
            return this.handleControlUpdate(
                action,
                productData,
                trackActionUndo
            )
        },
        async handleSmartOverrideBehavior(
            action,
            smartChecked,
            trackActionUndo
        ) {
            const { id, type, key, eventId } = action
            if (type === 'photo' && ['posX', 'posY', 'size'].includes(key)) {
                const additionalAction = {
                    id,
                    type,
                    eventId,
                    key: ['posX', 'posY'].includes(key)
                        ? 'smart_position'
                        : 'smart_size',
                    value: smartChecked ? null : { override: false },
                }
                return this.handleControlUpdate(
                    additionalAction,
                    null,
                    trackActionUndo
                )
            }
            if (type === 'moveable' && key === 'transform') {
                const additionalAction = {
                    id,
                    type,
                    eventId,
                    key: 'smart_transform',
                    value: smartChecked ? null : { override: false },
                }
                return this.handleControlUpdate(
                    additionalAction,
                    null,
                    trackActionUndo
                )
            }
        },
        async handleSmartUpdateOfWholeConnection(action) {
            // POC | Perform a "Smart Update":
            if (!action.key.startsWith('smart_')) {
                // smart updates only for smart keys...
                return
            }

            const { /*id, */ connectionId, template } = this.creation
            const templateId = template?.id
            if (!templateId) {
                return
            }
            // [!!] if creation settings exist for this action, we suppose to reset them:
            // const creationResetAction = { ...action, value: null }
            this.updateHolder({ ...action, templateId })
            const updatedSettings =
                await connectionService.updateConnectionSettings(
                    {
                        projectId: this.projectId,
                        connectionId,
                    },
                    action
                )
            this.setNewConnectionSettings({
                templateId,
                settingsByConnections: { [connectionId]: updatedSettings },
            })
            this.creation.template.applySettings(updatedSettings)
        },
        handleResetCreationSettings() {
            Modal.confirm({
                title: 'Reset settings?',
                content: 'You will lose all changes in this creation.',
                cancelText: 'Cancel',
                okText: 'Reset',
                icon: 'exclamation-circle',
                onOk: () => {
                    this.resetSettings()
                },
            })
        },
        reloadUIState() {
            // This is a HACK, but works with v-if
            // AIM is to remove 2 components responsible for state to REFRESH all of the things:
            // Movable lib + forms + elements positions
            this.showUI = false
            this.$nextTick(() => {
                this.showUI = true
            })
        },
        async resetSettings() {
            try {
                const { cleanSettings } =
                    await connectionService.resetCreationSettings({
                        projectId: this.projectId,
                        creationId: this.creation.id,
                    })
                await this.cleanCurrentCreationSettings(cleanSettings)
                this.reloadUIState()
                this.showAlertSuccess('Settings have been reset')
            } catch (e) {
                console.error(e)
            }
        },
    },
}
</script>

<style lang="less">
.ruler {
    &-vertical {
        top: 35px;
        left: 0;
        width: 35px;
        position: absolute;
    }

    &-horizontal {
        top: 0;
        left: 35px;
        height: 35px;
        position: absolute;
    }
}

.sbanner {
    position: absolute;
    top: 64px;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 100%;
    background-color: @gray-1;
    z-index: 999;

    &__editor {
        position: relative;
    }

    .edition__box {
        padding: 0 10px;
        border: none;
        max-height: 100%;
        overflow-y: scroll;
        background-color: @gray-3;
    }

    .badges__list {
        grid-template-columns: 1fr 1fr;
    }

    .badges__form .label .badges__content {
        font-size: 10px;
    }

    &__main {
        display: flex;
        height: calc(100% - 64px - 48px);
    }

    &__bottom {
        padding: 0 24px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 48px;
        background-color: @gray-2;
        border-top: solid 1px @gray-4;
    }

    &__download {
        margin-right: 8px;
    }

    &__reset {
        margin-right: 8px;
        color: @red-5;
    }

    &__side {
        padding: 16px;
        background-color: @gray-2;
        width: 360px;
    }

    &__preview {
        position: relative;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__undo {
        border-right: 0.1em solid @gray-6;
        padding-right: 1.5em;
    }

    &__smart-object {
        margin-left: 1.2em;
    }

    &__size {
        position: absolute;
        top: 40px;
        right: 16px;
        display: flex;
        align-items: center;
        z-index: 100;

        &-text {
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}
</style>
