import { TControl } from './TControl'
import {
    isCoverOrContain,
    isNumeric,
    isPercent,
    sanitizeUrl,
    undefinedToNull,
} from './templating-helpers'
import { photoLoader } from '@/templating/photo-editions/photo-loader'
import { photoBackgroundMover } from '@/templating/photo-editions/photo-background-mover'
import { fireOnWindowLoadEventOnce } from '@/templating/page-events/listeners'

const HORIZONTAL_FLIP = {
    KEY: 'flip',
    VALUE: 'h',
}
const composeHorizontalFlip = (flip, url) => {
    try {
        const composedURL = new URL(url)
        if (flip) {
            composedURL.searchParams.set(
                HORIZONTAL_FLIP.KEY,
                HORIZONTAL_FLIP.VALUE
            )
        } else {
            composedURL.searchParams.delete(HORIZONTAL_FLIP.KEY)
        }
        return composedURL.toString()
    } catch (e) {
        console.warn(e)
        return url
    }
}

export class TemplatePhoto extends TControl {
    static $selector = '[data-photo]'
    urlPattern = /(url\("?|"?\);?$)/g
    horizontalFlop = false

    moveEnabled = true

    constructor(ref, baseUrl, container) {
        super(ref, 'photo')
        this.container = container
        this.id = this.ref.getAttribute(TemplatePhoto.$selector.slice(1, -1))
        this.baseUrl = baseUrl
        this.calculateURL()
    }

    calculateURL() {
        const computedStyle = getComputedStyle(this.ref)
        this.url = computedStyle.backgroundImage.replace(this.urlPattern, '')
    }

    get hFlip() {
        return this.horizontalFlop
    }

    set hFlip(value) {
        this.horizontalFlop = value
        this.calculateURL()
    }

    get url() {
        const address = this.ref.style.backgroundImage.replace(
            this.urlPattern,
            ''
        )
        return sanitizeUrl(address, this.baseUrl)
    }

    set url(value) {
        const sanitizedURL = composeHorizontalFlip(
            this.horizontalFlop,
            sanitizeUrl(value, this.baseUrl)
        )
        const hasUrlChanged = this.url !== sanitizedURL
        this.ref.style.backgroundImage = `url("${sanitizedURL}")`
        if (this.isReactive && hasUrlChanged) {
            this.reloadBackground()
                .then(() => {})
                .catch((err) => {
                    console.error('BG reload error', err)
                })
        }
    }

    get posX() {
        const { backgroundPositionX } = getComputedStyle(this.ref)
        if (backgroundPositionX === '0%') {
            return undefined
        }
        return isPercent(backgroundPositionX)
            ? backgroundPositionX
            : parseInt(backgroundPositionX)
    }

    set posX(value) {
        this.ref.style.backgroundPositionX = valueResolver(value)
        this.updateMovableBox()
        // console.log('posX is present...', valueResolver(value))
    }

    get posY() {
        const { backgroundPositionY } = getComputedStyle(this.ref)
        if (backgroundPositionY === '0%') {
            return undefined
        }
        return isPercent(backgroundPositionY)
            ? backgroundPositionY
            : parseInt(backgroundPositionY)
    }

    set posY(value) {
        this.ref.style.backgroundPositionY = valueResolver(value)
        this.updateMovableBox()
        // console.log('posY', valueResolver(value))
    }

    // 2nd tryout - smart adaptation (WIP)
    set smart_size(value) {
        if (!value || value.override === false) {
            return
        }
        this.smartUpdate(() => {
            const { size } = value
            const baseRect = this.ref.getBoundingClientRect()
            // const changedPercent = (width * 100) / baseRect.width
            if (size) {
                this.size = (size / 100) * baseRect.width
            }
        })
    }

    set smart_position(value) {
        if (!value || value.override === false) {
            return
        }
        this.smartUpdate(() => {
            const { posX, posY } = value
            const baseRect = this.ref.getBoundingClientRect()
            if (posX) {
                this.posX = (posX / 100) * baseRect.width
            }
            if (posY) {
                this.posY = (posY / 100) * baseRect.height
            }
        })
    }

    get size() {
        const { backgroundSize } = getComputedStyle(this.ref)
        if (backgroundSize === 'auto') {
            return undefined
        }
        return isCoverOrContain(backgroundSize) || isPercent(backgroundSize)
            ? backgroundSize
            : parseInt(backgroundSize)
    }

    set size(value) {
        this.ref.style.backgroundSize = valueResolver(
            value,
            (v) => isCoverOrContain(v) || isPercent(v)
        )
        this.updateMovableBox()
    }

    async setupReactivity() {
        super.isReactive = true
        await this.reloadBackground()
    }

    async reloadBackground() {
        if (this.moveable) {
            this.moveable.destroy()
            // We need it pristine (to not fire twice)
            this.moveable = undefined
        }
        if (!this.url) {
            // Do nothing if the url is empty
            return
        }
        const { width, height, imageRatio } = await photoLoader(
            this.url,
            this.size,
            this.ref
        )
        let smartSizeHolder
        let smartPositionHolder
        const baseRect = this.ref.getBoundingClientRect()
        const dispatchPositionChange = () => {
            const eventId = this.makeEventId()
            this.dispatch(this.id, 'posX', this.posX, eventId)
            this.dispatch(this.id, 'posY', this.posY, eventId)
            this.dispatch(
                this.id,
                'smart_position',
                smartPositionHolder,
                eventId
            )
            this.smart_position = smartPositionHolder
        }
        const updateNewPosition = ({
            left,
            top,
            smartPosition,
            movedByArrows,
        }) => {
            this.posX = left
            this.posY = top
            smartPositionHolder = smartPosition
            if (movedByArrows) {
                // @fix, move by arrows should also dispatch changes
                dispatchPositionChange()
            }
        }
        this.moveable = photoBackgroundMover({
            width,
            height,
            baseRect,
            imageRatio,
            targetName: this.id,
            targetControl: this,
            baseX: this.posX,
            baseY: this.posY,
            onMove: updateNewPosition,
            onResize: ({ width, smartSize }) => {
                this.size = width
                smartSizeHolder = smartSize
            },
            onDragEnd: dispatchPositionChange,
            onResizeEnd: () => {
                const eventId = this.makeEventId()
                this.dispatch(this.id, 'size', this.size, eventId)
                this.dispatch(this.id, 'smart_size', smartSizeHolder, eventId)
                this.smart_size = smartSizeHolder
            },
        })
        // @Fix: movable need to be "refreshed" if load event happens after setupReactivity fired
        fireOnWindowLoadEventOnce(() => this.updateMovableBox())
    }

    updateMovableBox() {
        if (this.isReactive && this.moveable) {
            this.moveable.update(this.posX, this.posY, this.size)
        }
    }

    onDestroy() {
        if (this.moveable) {
            this.moveable.destroy()
        }
    }
}

function valueResolver(value, preserveValueIf = (value) => isPercent(value)) {
    return preserveValueIf(value)
        ? value
        : isNumeric(value)
          ? value + 'px'
          : // THIS is CRUCIAL to reset inline css style values !!!
            undefinedToNull(value)
}
