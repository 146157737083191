<template>
    <a-modal
        v-model="visible"
        :maskClosable="false"
        :title="'Possible Values for: ' + template.name"
        @cancel="handleModalClose(true)"
        width="80vw"
        size="small"
    >
        <a-tabs @change="handleTabChange">
            <a-tab-pane
                v-for="{ title, language, value } of languageTabs"
                :key="value"
            >
                <template #tab>
                    <div v-if="language === LANG_FALLBACK">{{ title }}</div>
                    <locale-picker
                        v-else
                        :read-only="true"
                        :show-label="false"
                        :value="language"
                    />
                </template>
            </a-tab-pane>
            <template #tabBarExtraContent>
                <a-popconfirm
                    title="Are you sure, that will overwrite all of current defaults!?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="handleLoadOptionalValuesFromTemplate()"
                >
                    <a-button v-if="hasInTemplateStaticValues">
                        Load default from Template
                    </a-button>
                </a-popconfirm>
            </template>
        </a-tabs>
        <a-table
            :columns="columns"
            :data-source="possibleValueRows"
            :expand-row-by-click="true"
        >
            <template #nameColumn="{ no, group, name, frozen }">
                <template v-if="no">
                    <p v-if="hasDisplayName(name)">
                        {{ cssVarsDisplayNames[name] }}
                    </p>
                    <code v-if="hasDisplayName(name)" style="opacity: 0.6">
                        {{ name }}
                    </code>
                    <span v-else>{{ name }}</span>
                    <template v-if="hasAnyFrozen(frozen)">
                        <a-icon
                            class="ant-btn-link"
                            type="lock"
                            :title="getTruthyFrozenKeys(frozen).join(', ')"
                        />
                    </template>
                </template>
            </template>
            <template #possibleValues="{ no, group, name, values, dataShape }">
                <possible-values-preview-table-cell
                    :field-name="name"
                    :template-id="template.id"
                    :values="values"
                    :data-shape="dataShape"
                />
            </template>
            <template
                #expandedRowRender="{
                    no,
                    group,
                    name,
                    displayName,
                    values,
                    frozen,
                    dataShape,
                }"
            >
                <div
                    style="margin: 0 2em 1em 17%; padding-right: 2em"
                    v-if="isCssVariablesGroup(group)"
                >
                    Display name:
                    <a-input
                        style="width: 50%; margin-left: 2em"
                        v-model="cssVarsDisplayNames[name]"
                        @change="displayNameChanged = true"
                    ></a-input>
                </div>
                <div v-if="no" style="margin-left: 0; display: flex">
                    <div
                        style="
                            margin: 0 2em 0 17%;
                            padding-right: 2em;
                            border-right: 1px solid #7a7a7a;
                        "
                    >
                        <frozen-fields-divisor
                            :frozen-shape="getFrozenShapeFor(group)"
                            :base-frozen-values="frozen"
                            @update="
                                handleFrozenUpdated({
                                    group,
                                    name,
                                    values: $event,
                                })
                            "
                        />
                    </div>
                    <possible-values-divisor
                        :data-shape="dataShape"
                        :data-values="values"
                        :field-name="name"
                        :fontNames="templateFontNames"
                        :template-id="template.id"
                        @update="
                            handleValueUpdated({ group, name, values: $event })
                        "
                    />
                </div>
            </template>
        </a-table>

        <template #footer>
            <a-button
                v-if="hasAnyChanges"
                key="back"
                type="default"
                @click="handleModalClose()"
            >
                Discard changes
            </a-button>
            <a-button
                key="submit"
                type="primary"
                @click="handleSaveClick()"
                :disabled="!hasAnyChanges"
            >
                Save possible values
            </a-button>
        </template>
        <section v-show="false">
            <div ref="mountPoint"></div>
        </section>
    </a-modal>
</template>

<script>
import { TemplateModel } from '@/components/project/model/TemplateModel'
import LocalePicker from '@/components/shared/locale-picker/LocalePicker'
import FrozenFieldsDivisor from '@/components/templates/possible-values/FrozenFieldsDivisor'
import {
    SHAPE,
    SHAPE_GROUP_GROUP_KEYS,
    SHAPE_GROUP_KEY_MAP,
    SHAPE_GROUP_KEYS_MAPPER,
} from '@/components/templates/possible-values/possible-values-shape'
import PossibleValuesDivisor from '@/components/templates/possible-values/PossibleValuesDivisor'
import {
    LANG_FALLBACK,
    LANG_LOCALES,
} from '@/components/shared/supported-languages'
import PossibleValuesPreviewTableCell from '@/components/templates/possible-values/PossibleValuesPreviewTableCell'
import { templateFrozenFieldsService } from '@/services/template-frozen-fields.service'
import { templatePossibleValuesService } from '@/services/template-possible-values.service'
import { templateService } from '@/services/template.service'
import { TemplateText } from '@/templating/TemplateText'
import { Modal } from 'ant-design-vue'
import { TemplateMoveable } from '@/templating/TemplateMoveable'

export default {
    name: 'PossibleValuesModal',
    components: {
        FrozenFieldsDivisor,
        PossibleValuesPreviewTableCell,
        LocalePicker,
        PossibleValuesDivisor,
    },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            visible: true,
            cssVarsDisplayNames: {},
            displayNameChanged: false,
            possibleValueRows: [],
            possibleValuesData: {},
            frozenFieldsData: {},
            inTemplatePossibleValues: null,
            chosenLang: LANG_FALLBACK,
            editedLanguages: [],
            editedFrozenFields: {},
            templateModel: new TemplateModel(),
        }
    },
    computed: {
        languageTabs() {
            return [
                {
                    title: 'Fallback',
                    language: LANG_FALLBACK,
                    value: LANG_FALLBACK,
                },
                ...Object.entries(LANG_LOCALES).map(([speech, language]) => ({
                    title: '',
                    language: language,
                    value: speech,
                })),
            ]
        },
        columns() {
            return [
                {
                    title: 'No.',
                    width: '15%',
                    dataIndex: 'no',
                    customRender: (value, row) => {
                        const config = { children: value }
                        const { no, name } = row
                        if (no === 0) {
                            config.children = <b>{name}</b>
                        }
                        return config
                    },
                },
                {
                    title: 'Name',
                    width: '20%',
                    scopedSlots: { customRender: 'nameColumn' },
                },
                {
                    title: 'Possible values',
                    scopedSlots: { customRender: 'possibleValues' },
                },
            ]
        },
        hasAnyChanges() {
            return (
                this.editedLanguages.length > 0 ||
                this.hasFrozenFieldChanges ||
                this.displayNameChanged
            )
        },
        hasFrozenFieldChanges() {
            return Object.keys(this.editedFrozenFields).length > 0
        },
        hasInTemplateStaticValues() {
            // There should be no changes to be able to do so !
            return Boolean(this.inTemplatePossibleValues) && !this.hasAnyChanges
        },
        templateFontNames() {
            return this.templateModel?.usedFontsMetadata?.names
        },
        SHAPE() {
            return SHAPE
        },
        SHAPE_GROUP_KEY_MAP() {
            return SHAPE_GROUP_KEY_MAP
        },
        LANG_FALLBACK() {
            return LANG_FALLBACK
        },
    },
    async mounted() {
        try {
            const { id } = this.template
            const [possibleValues, frozenFields] = await Promise.all([
                templatePossibleValuesService.getValues(id),
                templateFrozenFieldsService.getValues(id),
            ])
            this.possibleValuesData = possibleValues || {}
            this.frozenFieldsData = frozenFields?.values || {}
            this.makeTableRowsFeed()
            // Step #2 extract template model:
            const request = templateService.provideTemplateModel(
                this.template.id,
                this.$refs.mountPoint,
                this.template.settings
            )
            this.templateModel = await request.call()
            this.inTemplatePossibleValues =
                templatePossibleValuesService.extractInTemplateDefaultValues(
                    this.templateModel
                )
        } catch (e) {
            console.log(e)
        }
    },
    methods: {
        makeTableRowsFeed() {
            const { settings } = this.template || {}
            if (settings) {
                this.possibleValueRows = calculateDefaultValues(
                    settings,
                    this.getCurrentLangValue.bind(this),
                    this.getFrozenFieldValue.bind(this),
                    this.makeDefaultCssVarName.bind(this)
                )
            }
        },
        hasFrozenShape(groupKey) {
            return groupKey !== this.SHAPE_GROUP_KEY_MAP.cssVariables
        },
        isCssVariablesGroup(group) {
            return group === this.SHAPE_GROUP_KEY_MAP.cssVariables
        },
        hasDisplayName(variableName) {
            return Boolean(this.cssVarsDisplayNames[variableName])
        },
        makeDefaultCssVarName(name, displayName) {
            this.$set(this.cssVarsDisplayNames, name, displayName || '')
        },
        getFrozenShapeFor(groupKey) {
            const {
                text,
                photo,
                image,
                mask,
                background,
                border,
                cssVariables,
                moveable,
            } = this.SHAPE_GROUP_KEY_MAP
            switch (groupKey) {
                case text:
                    return generateFlagObject('text', ...TemplateText.freezable)
                case photo:
                case image:
                case mask:
                    return generateFlagObject('upload', 'gallery')
                case background:
                case border:
                    return generateFlagObject(this.SHAPE.color)
                case cssVariables:
                    return generateFlagObject('variable')
                case moveable:
                    return generateFlagObject(...TemplateMoveable.freezable)
                default:
                    return {}
            }
        },
        hasAnyFrozen(values) {
            return Object.values(values).some(Boolean)
        },
        getTruthyFrozenKeys(values) {
            return Object.entries(values)
                .filter(([, value]) => value)
                .map(([key]) => key)
        },
        handleValueUpdated({ group, name, values }) {
            // console.log({ group, name, values })
            this.saveCurrentLangValues({ group, name, values })
        },
        handleFrozenUpdated({ group, name, values }) {
            if (!this.editedFrozenFields[group]) {
                this.$set(this.editedFrozenFields, group, {})
            }
            this.editedFrozenFields[group][name] = values
        },
        handleTabChange(value) {
            this.chosenLang = value
            for (const row of this.possibleValueRows) {
                if (row.values) {
                    const { group, name } = row
                    row.values = this.getCurrentLangValue({ group, name })
                }
            }
        },
        async handleSaveClick() {
            for (const lang of this.editedLanguages) {
                try {
                    const values = this.possibleValuesData[lang]
                    await templatePossibleValuesService.saveForLanguage(
                        this.template.id,
                        lang,
                        values
                    )
                } catch (e) {
                    console.log(e)
                }
            }
            if (this.hasFrozenFieldChanges) {
                await templateFrozenFieldsService.saveValues(
                    this.template.id,
                    this.editedFrozenFields
                )
            }
            if (this.displayNameChanged) {
                try {
                    const templateSettings =
                        await templatePossibleValuesService.changeCssVariableNames(
                            this.template.id,
                            this.cssVarsDisplayNames
                        )
                    this.$emit('templateSettingsChanged', {
                        id: this.template.id,
                        settings: templateSettings,
                    })
                } catch (e) {
                    console.log(e)
                }
            }
            this.handleModalClose()
        },
        handleModalClose(showConfirmation = false) {
            const fireCloseEvent = () => {
                this.$emit('closeDefaultValuesModal')
            }
            if (this.hasAnyChanges && showConfirmation) {
                Modal.confirm({
                    title: 'Drop changes?',
                    content: 'If you close now, you will loose unsaved changes',
                    okText: 'Close',
                    onOk: fireCloseEvent,
                    onCancel: () => {
                        this.visible = true
                    },
                    cancelText: 'Cancel',
                    icon: 'exclamation-circle',
                })
            } else {
                fireCloseEvent()
            }
        },
        handleLoadOptionalValuesFromTemplate() {
            this.editedLanguages = []
            for (const [lang, values = {}] of Object.entries(
                this.inTemplatePossibleValues
            )) {
                if (Object.values(values).length > 0) {
                    this.editedLanguages.push(lang)
                    this.$set(this.possibleValuesData, lang, values)
                }
            }
            this.handleTabChange(this.chosenLang)
        },
        getCurrentLangValue({ group, name }) {
            const { chosenLang, possibleValuesData } = this
            return possibleValuesData?.[chosenLang]?.[group]?.[name] || {}
        },
        getFrozenFieldValue({ group, name }) {
            const { frozenFieldsData } = this
            return frozenFieldsData?.[group]?.[name] || {}
        },
        saveCurrentLangValues({ group, name, values }) {
            const { chosenLang, possibleValuesData, possibleValueRows } = this
            if (!possibleValuesData[chosenLang]) {
                this.$set(possibleValuesData, chosenLang, {})
            }
            if (!possibleValuesData[chosenLang][group]) {
                possibleValuesData[chosenLang][group] = {}
            }
            possibleValuesData[chosenLang][group][name] = values
            const updatedRow = possibleValueRows.find(
                (row) => row.name === name && row.group === group
            )
            if (updatedRow) {
                // Update values on the table:
                updatedRow.values = values
            }
            // Track changes:
            if (!this.editedLanguages.includes(chosenLang)) {
                this.editedLanguages.push(chosenLang)
            }
        },
    },
}

function calculateDefaultValues(
    settings = {},
    currentValue,
    currentFrozenField,
    makeDefaultCssVarName
) {
    const defaultValues = []
    let no = 1
    for (const key of SHAPE_GROUP_GROUP_KEYS) {
        const settingsValue = settings[key]
        if (settingsValue) {
            const settingIds = Object.keys(settingsValue)
            if (settingIds.length) {
                defaultValues.push({
                    key: key,
                    no: 0,
                    name: SHAPE_GROUP_KEYS_MAPPER[key].name,
                })
            }
            for (const name of settingIds) {
                // For now only for SHAPE_GROUP_KEY_MAP.cssVariables
                if (key === SHAPE_GROUP_KEY_MAP.cssVariables) {
                    const { displayName } = settingsValue[name] || {}
                    makeDefaultCssVarName(name, displayName)
                }
                const values = currentValue({ group: key, name })
                const frozen = currentFrozenField({ group: key, name })
                const dataShape = SHAPE_GROUP_KEYS_MAPPER[key].dataShape
                defaultValues.push({
                    key: key + name,
                    no: no++,
                    name,
                    group: key,
                    values,
                    frozen,
                    dataShape,
                })
            }
        }
    }
    return defaultValues
}

function generateFlagObject(...keys) {
    return Object.fromEntries(keys.map((k) => [k, false]))
}
</script>

<style scoped></style>
