import { MOVABLE_CONTROL_BTN } from '@/templating/movables/control-types'
import {
    addButton,
    ownerWrapper,
} from '@/templating/movables/movable-gui-helpers'
import { isPercent } from '@/templating/templating-helpers'
import Moveable from 'moveable'
import { selectCurrentToMoveByArrows } from '@/templating/movables/move-by-arrows'

const BTN_RIGHT_MARGIN = 50

function moveTo(target, x, y) {
    target.style.left = `${x}px`
    target.style.top = `${y}px`
}

function resizeTo(target, width, height) {
    target.style.width = `${width}px`
    target.style.height = `${height}px`
}

function moveByArrows({ div, btn, onMove, mX, mY, baseRect }) {
    return (x, y) => {
        const target = div
        const top = parseInt(div.style.top)
        const left = parseInt(div.style.left)

        const baseLeft = left - mX
        const baseTop = top - mY
        const leftPercentage = (baseLeft / baseRect.width) * 100
        const topPercentage = (baseTop / baseRect.height) * 100
        moveTo(target, left + x, top + y)
        moveTo(btn, left + x - BTN_RIGHT_MARGIN, top + y)
        onMove({
            left: left + x - mX,
            top: top + y - mY,
            smartPosition: {
                posX: leftPercentage,
                posY: topPercentage,
            },
            movedByArrows: true,
        })
    }
}

export function photoBackgroundMover({
    targetName,
    baseRect,
    baseX,
    baseY,
    width,
    height,
    onMove = () => {},
    onResize = () => {},
    imageRatio = 1,
    onDragEnd = () => {},
    onResizeEnd = () => {},
    targetControl,
}) {
    const parentWrapper = ownerWrapper()
    const wrapperRect = parentWrapper.getBoundingClientRect()
    const moveByX = baseRect.x - wrapperRect.x
    const moveByY = baseRect.y - wrapperRect.y
    const startX = percentToNumberAdapt(baseX, baseRect.width, width) + moveByX
    const startY =
        percentToNumberAdapt(baseY, baseRect.height, height) + moveByY
    const scalableDiv = document.createElement('div')
    const editBtn = addButton({
        startX,
        startY,
        targetName,
        typeId: MOVABLE_CONTROL_BTN.photoBackgroundFrameToggle,
    })
    const moveByArrowsListener = () => {
        selectCurrentToMoveByArrows(
            targetControl,
            moveByArrows({
                div: scalableDiv,
                btn: editBtn,
                onMove,
                mX: moveByX,
                mY: moveByY,
                baseRect,
            })
        )
    }
    scalableDiv.style.position = 'absolute'
    scalableDiv.style.top = `${startY}px`
    scalableDiv.style.left = `${startX}px`
    scalableDiv.style.width = width + 'px'
    scalableDiv.style.height = height + 'px'
    scalableDiv.style.zIndex = '999'
    scalableDiv.addEventListener('click', moveByArrowsListener)
    parentWrapper.appendChild(scalableDiv)
    parentWrapper.appendChild(editBtn)
    const movableElement = new Moveable(parentWrapper, {
        target: scalableDiv,
        origin: false,
        draggable: true,
        resizable: true,
        keepRatio: true,
        className: 'movable-red',
    })
        .on('drag', ({ target, left, top }) => {
            const baseLeft = left - moveByX
            const baseTop = top - moveByY
            const leftPercentage = (baseLeft / baseRect.width) * 100
            const topPercentage = (baseTop / baseRect.height) * 100
            moveTo(target, left, top)
            moveTo(editBtn, left - BTN_RIGHT_MARGIN, top)
            onMove({
                left: left - moveByX,
                top: top - moveByY,
                smartPosition: {
                    posX: leftPercentage,
                    posY: topPercentage,
                },
            })
        })
        .on('resize', ({ target, width, height }) => {
            const changedPercent = (width * 100) / baseRect.width // change is relative to container width
            resizeTo(target, width, height)
            onResize({
                width,
                smartSize: {
                    size: changedPercent,
                },
            })
        })
        .on('resizeEnd', onResizeEnd)
        .on('dragEnd', onDragEnd)

    const btnIcon = editBtn.querySelector('svg')
    targetControl.ref.style.pointerEvents = 'auto'
    scalableDiv.style.pointerEvents = 'auto'
    editBtn.addEventListener('click', () => {
        const { style } = scalableDiv
        const isShown = style.display === 'block' || !style.display
        style.display = isShown ? 'none' : 'block'
        targetControl.ref.style.pointerEvents = isShown ? 'none' : 'auto'
        targetControl.moveEnabled = !isShown
        btnIcon.setAttribute('fill', isShown ? 'currentColor' : 'red')
        movableElement.updateRect()
        editBtn.blur()
        moveByArrowsListener()
    })

    return {
        destroy() {
            scalableDiv.remove()
            editBtn.remove()
            movableElement.destroy()
        },
        update(x, y, size) {
            const calculateX = percentToNumberAdapt(
                x,
                baseRect.width,
                scalableDiv.style.width
            )
            const calculateY = percentToNumberAdapt(
                y,
                baseRect.height,
                scalableDiv.style.height
            )
            const calculateWidth = coverOrContainToNumberAdapt(
                size,
                width,
                baseRect.width
            )
            moveTo(scalableDiv, calculateX + moveByX, calculateY + moveByY)
            moveTo(editBtn, calculateX + moveByX - 50, calculateY + moveByY)
            resizeTo(scalableDiv, calculateWidth, calculateWidth / imageRatio)
            movableElement.updateRect()
        },
    }
}

function percentToNumberAdapt(value, baseDimension, relativeDimension) {
    if (!value) {
        // fallback to 0! cause posX or posY default value is undefined
        return 0
    }
    return isPercent(value)
        ? percentOf(baseDimension, value) - percentOf(relativeDimension, value)
        : value
}

function coverOrContainToNumberAdapt(value, imageWidth, containerWidth) {
    if (value === 'contain') {
        return containerWidth
    }
    if (value === 'cover') {
        return imageWidth
    }
    if (isPercent(value)) {
        return percentOf(containerWidth, value)
    }
    return value
}

function percentOf(value, percent) {
    return parseFloat(value) * (parseFloat(percent) / 100)
}
